import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { School, SchoolSave, SchoolRead, SchoolDelete, SchoolUpdate } from '@/api/ceshi_xiugai/product';
import area from '@/api/area.js';
import Head from '@/components/head/index';
import DynamicForm from '@/components/DynamicForm/index';
import Upload from '@/components/Upload/SingleImage3';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import mailListEs from '@/components/StaffSelect/mailListRadioEs1';
export default {
  name: 'CollegeManagement',
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      //系统教程
      title: '院校管理',
      area: area,
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      editorOption: {
        placeholder: 'Hello World'
      },
      currentPage4: 1,
      listLoading: true,
      //加载动画
      dialogFormVisible: false,
      //弹窗
      tableData: [],
      //院校列表
      checkedCities: [],
      //项目分类选择的值
      cities: [],
      //项目分类所有选项
      attribute: [],
      //院校属性选中的值
      attributeUnchecked: [],
      //院校属性所有选项
      rules: {
        title: [{
          required: true,
          message: '请输入院校名称',
          trigger: 'blur'
        }],
        area: [{
          required: true,
          message: '请选择国家地区',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        }],
        admin: [{
          required: true,
          message: '请选择院校管理员',
          trigger: 'change'
        }],
        charge: [{
          required: true,
          message: '请选择是否参与收费',
          trigger: 'change'
        }],
        arrangement: [{
          required: true,
          message: '请选择院校留学层次',
          trigger: 'change'
        }],
        shoolType: [{
          required: true,
          message: '请选择院校类型',
          trigger: 'change'
        }]
      },
      textMap: {
        update: '编辑院校',
        create: '新建院校'
      },
      dialogStatus: '',
      areaSearch: '',
      is_charge: '',
      type: '',
      schoolName: '',
      adminSearch: '',
      charge_Array: [{
        id: 1,
        name: '是'
      }, {
        id: 2,
        name: '否'
      }],
      type_Array: [{
        id: 1,
        name: '公立'
      }, {
        id: 2,
        name: '私立'
      }],
      form: {
        title: '',
        // 院校名称
        area: '',
        // 省-市-县
        admin: '',
        charge: '2',
        arrangement: '',
        shoolType: '1',
        authentication: '',
        project_grade: '',
        // 项目评级
        introduce: '',
        // 简介
        school_grade: '',
        // 摘要
        address: '',
        // 详细地址
        file: '' // 附件
      },
      area1: [],
      adminName: '',
      tempId: '',
      value1: '',
      fileList: [],
      text: [],
      condition: [],
      staffResultEs: [],
      mailListVisibleEs: false,
      radioEs: false,
      readVisible: false,
      disabledShow: false
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
    } else {}
  },
  components: {
    Head: Head,
    DynamicForm: DynamicForm,
    Upload: Upload,
    quillEditor: quillEditor,
    mailListEs: mailListEs
  },
  methods: {
    getList: function getList(page, pagesize) {
      var _this = this;
      //院校列表
      this.listLoading = true;
      var area;
      if (this.areaSearch.length > 1) {
        area = this.areaSearch[1];
      } else {
        area = String(this.areaSearch);
      }
      School({
        page: page,
        pagesize: pagesize,
        is_charge: this.is_charge,
        province: area,
        type: this.type,
        title: this.schoolName,
        administrators: this.adminSearch
      }).then(function (respomse) {
        var data = respomse.data;
        _this.tableData = data.school_list.data;
        _this.query.total = data.school_list.total;
        _this.cities = data.school_project;
        _this.attributeUnchecked = data.school_attr;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = [];
        _this.listLoading = false;
      });
    },
    mailList: function mailList() {
      this.radioEs = true;
      this.mailListVisibleEs = true;
      this.disabledShow = true;
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      this.adminName = String(resultNameArr);
      this.form.admin = String(result);
      this.text = result;
      this.disabledShow = false;
    },
    cancel: function cancel() {
      this.disabledShow = false;
    },
    // 选择地区
    handleChange: function handleChange(val) {
      this.area1 = val;
    },
    areaChange: function areaChange(val) {
      // if (val.length > 1) {
      //     this.areaSearch = val[1];
      // } else {
      //     this.areaSearch = String(val);
      // }
    },
    ProjectClassification: function ProjectClassification(val) {
      //获取项目分类
      this.checkedCities = val;
    },
    courtyardAttribute: function courtyardAttribute(val) {
      //获取院校属性
      this.attribute = val;
    },
    enclosure: function enclosure(val) {
      //获取附件id
      this.form.file = this.form.file + ',' + String(val);
      var file = this.form.file.split(',');
      this.form.file = _toConsumableArray(new Set(file));
      this.form.file = String(this.form.file);
    },
    remove: function remove(a, b) {
      var c = a.split(','),
        d = '';
      for (var i = 0; i < c.length; i++) {
        d += c[i] == b ? '' : ',' + c[i];
      }
      return d.length > 0 ? d.substring(1) : '';
    },
    delFile: function delFile(val) {
      this.form.file = this.remove(this.form.file, val);
    },
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    queryClick: function queryClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    resetTemps: function resetTemps() {
      var _this2 = this;
      //清空班级弹窗
      this.form = {
        title: '',
        // 院校名称
        area: '',
        // 省
        admin: '',
        charge: '2',
        arrangement: '',
        shoolType: '1',
        authentication: '',
        project_grade: '',
        //
        introduce: '',
        // 简介
        school_grade: '',
        // 摘要
        address: '',
        // 详细地址
        file: '',
        // 附件
        adminName: ''
      };
      this.area1 = [];
      this.checkedCities = [];
      this.attribute = [];
      this.fileList = [];
      this.readVisible = false;
      this.adminName = '';
      this.$nextTick(function () {
        _this2.$refs['ruleForm'].clearValidate();
      });
      this.text = [];
      this.disabledShow = false;
    },
    Announcement: function Announcement(formName) {
      var _this3 = this;
      //新建院校
      var formEor = this.form;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            title: formEor.title,
            project_type: String(_this3.checkedCities),
            school_attr: String(_this3.attribute),
            province: _this3.area1[0],
            city: _this3.area1[1],
            country: _this3.area1[2],
            address: formEor.address,
            is_charge: formEor.charge,
            administrators: formEor.admin,
            level: formEor.arrangement,
            type: formEor.shoolType,
            authentication: formEor.authentication,
            project_grade: formEor.project_grade,
            introduce: formEor.introduce,
            school_grade: formEor.school_grade,
            file_id: formEor.file
          };
          SchoolSave(data).then(function (res) {
            _this3.dialogFormVisible = false;
            _this3.$notify({
              title: '成功',
              message: '新建院校成功',
              type: 'success',
              duration: 2000
            });
            _this3.getList(_this3.query.page, _this3.query.pagesize);
            _this3.resetTemps();
          });
        } else {
          return false;
        }
      });
    },
    infodata: function infodata() {
      var _this4 = this;
      //获取班级详情
      if (this.tempId == '') {
        this.dialogFormVisible = false;
        this.$message({
          type: 'info',
          message: '请选择院校'
        });
      } else if (this.tempId.length > 1) {
        this.dialogFormVisible = false;
        this.$message({
          type: 'info',
          message: '请选择一个院校'
        });
      } else {
        SchoolRead({
          id: String(this.tempId)
        }).then(function (res) {
          _this4.readVisible = true;
          var data = res.data;
          _this4.area1 = [];
          _this4.area1.push(data.province);
          if (data.city) {
            _this4.area1.push(data.city);
          }
          if (data.country) {
            _this4.area1.push(data.country);
          }
          // this.area1.join('/');
          _this4.fileList = data.file_info;
          _this4.text.push(data.administrators);
          _this4.form = {
            title: data.title,
            // 院校名称
            area: _this4.area1,
            // 省
            project_grade: data.project_grade,
            // 校训
            introduce: data.introduce,
            // 简介
            school_grade: data.school_grade,
            // 摘要
            address: data.address,
            // 详细地址
            charge: String(data.is_charge),
            admin: data.administrators,
            arrangement: String(data.level),
            shoolType: String(data.type),
            authentication: String(data.authentication),
            file: String(data.file_id)
          };
          if (data.project_type != '') {
            _this4.checkedCities = data.project_type.split(',');
            _this4.checkedCities = _this4.checkedCities.map(function (item) {
              return item - 0;
            });
          }
          if (data.school_attr != '') {
            _this4.attribute = data.school_attr.split(',');
            _this4.attribute = _this4.attribute.map(function (item) {
              return item - 0;
            });
          }
          _this4.adminName = data.administrators_name;
        });
      }
    },
    EditorialAnnouncement: function EditorialAnnouncement(formName) {
      var _this5 = this;
      //编辑班级
      if (this.dialogStatus == 'update') {
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            var formEor = _this5.form;
            var data = {
              id: String(_this5.tempId),
              title: formEor.title,
              project_type: String(_this5.checkedCities),
              school_attr: String(_this5.attribute),
              province: _this5.area1[0],
              city: _this5.area1[1],
              country: _this5.area1[2],
              address: formEor.address,
              is_charge: formEor.charge,
              administrators: formEor.admin,
              level: formEor.arrangement,
              type: formEor.shoolType,
              authentication: formEor.authentication,
              project_grade: formEor.project_grade,
              introduce: formEor.introduce,
              school_grade: formEor.school_grade,
              file_id: formEor.file
            };
            SchoolSave(data).then(function (respomse) {
              _this5.getList(_this5.query.page, _this5.query.pagesize);
              _this5.dialogFormVisible = false;
              _this5.resetTemps();
              _this5.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
            });
          } else {
            return false;
          }
        });
      }
    },
    deleteBtn: function deleteBtn() {
      var _this6 = this;
      //删除班级
      if (this.tempId != '') {
        this.$confirm('此操作将永久删除院校，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var data = {
            id: String(_this6.tempId)
          };
          SchoolDelete(data).then(function (res) {
            _this6.getList(_this6.query.page, _this6.query.pagesize);
            _this6.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
          });
        }).catch(function () {
          _this6.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          type: 'info',
          message: '请选择院校'
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }

      // let arr = [];
      // val.forEach((item) => {
      //     arr.push(item.id);
      // });
      // this.tempId = arr;
    }
  }
};