var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container CollegeManagement" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-right": "0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("院校地区："),
                            ]),
                            _vm._v(" "),
                            _c("el-cascader", {
                              ref: "cascader",
                              attrs: {
                                value: _vm.areaSearch,
                                placeholder: "请选择院校地区",
                                options: _vm.area,
                                "expand-trigger": "hover",
                                props: { value: "label" },
                                size: "small",
                                clearable: "",
                              },
                              on: { change: _vm.areaChange },
                              model: {
                                value: _vm.areaSearch,
                                callback: function ($$v) {
                                  _vm.areaSearch = $$v
                                },
                                expression: "areaSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("是否参与收费："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请选择是否参与收费",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.is_charge,
                                  callback: function ($$v) {
                                    _vm.is_charge = $$v
                                  },
                                  expression: "is_charge",
                                },
                              },
                              _vm._l(_vm.charge_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("院校类型："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请选择院校类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.type,
                                  callback: function ($$v) {
                                    _vm.type = $$v
                                  },
                                  expression: "type",
                                },
                              },
                              _vm._l(_vm.type_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 5, lg: 5, xl: 3 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "院校名称搜索",
                                clearable: "",
                              },
                              model: {
                                value: _vm.schoolName,
                                callback: function ($$v) {
                                  _vm.schoolName = $$v
                                },
                                expression: "schoolName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 5, lg: 5, xl: 3 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "管理员名称搜索",
                                clearable: "",
                              },
                              model: {
                                value: _vm.adminSearch,
                                callback: function ($$v) {
                                  _vm.adminSearch = $$v
                                },
                                expression: "adminSearch",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.queryClick },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "10px 0px,padding:0px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogStatus = "create"),
                                  (_vm.dialogFormVisible = true),
                                  _vm.resetTemps(),
                                  (_vm.readVisible = true)
                              },
                            },
                          },
                          [_vm._v("新建")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogStatus = "update"),
                                  (_vm.dialogFormVisible = true),
                                  _vm.infodata()
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "danger" },
                            on: { click: _vm.deleteBtn },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        "element-loading-background": "rgba(0, 0, 0, 0.5)",
                        "element-loading-text": "数据正在加载中",
                        "element-loading-spinner": "el-icon-loading",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", align: "center" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "序号", align: "center", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3093164208
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "院校名称",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "院校地区", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.province != ""
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.province) + " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.city) + " "),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          596185671
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "administrators",
                          label: "院校管理员",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "is_charge",
                          label: "是否参与收费",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.is_charge == 1
                                    ? _c("span", [_vm._v("是")])
                                    : _c("span", [_vm._v("否")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4105687259
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "project_type_name",
                          label: "项目分类",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "school_attr_name",
                          label: "院校属性",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "level",
                          label: "院校留学层次",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.level == 1
                                    ? _c("span", [_vm._v("专班留学 ")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.level == 2
                                    ? _c("span", [_vm._v("普通留学 ")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.level == 3
                                    ? _c("span", [_vm._v("专班与普通留学 ")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2702905315
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          label: "院校类型",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type == 1
                                    ? _c("span", [_vm._v("公立")])
                                    : _c("span", [_vm._v("私立")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3622359672
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "authentication",
                          label: "教育认证",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.authentication == 1
                                    ? _c("span", [_vm._v("中留服认证 ")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.authentication == 2
                                    ? _c("span", [_vm._v("非中留服认证 ")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.authentication == 3
                                    ? _c("span", [_vm._v("其他 ")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          166913466
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "create_name",
                          label: "创建人",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.textMap[_vm.dialogStatus],
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: _vm.resetTemps,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-position": "right",
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "院校名称", prop: "title" },
                      },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "100" },
                          model: {
                            value: _vm.form.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "title", $$v)
                            },
                            expression: "form.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "国家地区", prop: "area" } },
                      [
                        _c("el-cascader", {
                          ref: "cascader",
                          attrs: {
                            value: _vm.form.area,
                            placeholder: "试试搜索：美国",
                            options: _vm.area,
                            "expand-trigger": "hover",
                            props: { value: "label" },
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.form.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "area", $$v)
                            },
                            expression: "form.area",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "详细地址", prop: "address" },
                      },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "200" },
                          model: {
                            value: _vm.form.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "address", $$v)
                            },
                            expression: "form.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "院校管理员", prop: "admin" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: _vm.disabledShow },
                          on: { focus: _vm.mailList },
                          model: {
                            value: _vm.adminName,
                            callback: function ($$v) {
                              _vm.adminName = $$v
                            },
                            expression: "adminName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "是否参与收费", prop: "charge" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.form.charge,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "charge", $$v)
                              },
                              expression: "form.charge",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "是", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "否", value: "2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "院校留学层次", prop: "arrangement" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.form.arrangement,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "arrangement", $$v)
                              },
                              expression: "form.arrangement",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "专班留学", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "普通留学", value: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "专班与普通留学", value: "3" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "院校类型", prop: "shoolType" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.shoolType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shoolType", $$v)
                              },
                              expression: "form.shoolType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("公立"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("私立 "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "项目分类" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.ProjectClassification },
                            model: {
                              value: _vm.checkedCities,
                              callback: function ($$v) {
                                _vm.checkedCities = $$v
                              },
                              expression: "checkedCities",
                            },
                          },
                          _vm._l(_vm.cities, function (city, index) {
                            return _c(
                              "el-checkbox",
                              { key: index, attrs: { label: city.id } },
                              [_vm._v(_vm._s(city.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "院校属性" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.courtyardAttribute },
                            model: {
                              value: _vm.attribute,
                              callback: function ($$v) {
                                _vm.attribute = $$v
                              },
                              expression: "attribute",
                            },
                          },
                          _vm._l(
                            _vm.attributeUnchecked,
                            function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.id } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "pct80", attrs: { label: "教育认证" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.form.authentication,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "authentication", $$v)
                              },
                              expression: "form.authentication",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "中留服认证", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "非中留服认证", value: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "其他", value: "3" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "项目评级", prop: "project_grade" },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", maxlength: "100" },
                          model: {
                            value: _vm.form.project_grade,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "project_grade", $$v)
                            },
                            expression: "form.project_grade",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "商学院评级", prop: "school_grade" },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", maxlength: "100" },
                          model: {
                            value: _vm.form.school_grade,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "school_grade", $$v)
                            },
                            expression: "form.school_grade",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "pct80",
                        attrs: { label: "院校简介", prop: "introduce" },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", maxlength: "500" },
                          model: {
                            value: _vm.form.introduce,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "introduce", $$v)
                            },
                            expression: "form.introduce",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "30px" },
                        attrs: { label: "其他附件", prop: "image_url" },
                      },
                      [
                        _vm.readVisible
                          ? _c("Upload", {
                              ref: "upload1",
                              attrs: { file: _vm.fileList },
                              on: {
                                "child-event": _vm.enclosure,
                                "del-event": _vm.delFile,
                              },
                              model: {
                                value: _vm.form.file,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "file", $$v)
                                },
                                expression: "form.file",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogStatus === "create"
                              ? _vm.Announcement("ruleForm")
                              : _vm.EditorialAnnouncement("ruleForm")
                          },
                        },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("mailListEs", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radioEs,
                text: _vm.text,
                condition: _vm.condition,
                visible: _vm.mailListVisibleEs,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleEs = $event
                },
                staffSelectConfirmEs: _vm.staffSelectConfirmEs,
                staffSelectCancel: _vm.cancel,
              },
              model: {
                value: _vm.staffResultEs,
                callback: function ($$v) {
                  _vm.staffResultEs = $$v
                },
                expression: "staffResultEs",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }