var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.field, function (e, i) {
      return _c(
        "el-form-item",
        {
          key: i,
          class: e.class,
          attrs: { label: e.label, prop: e.modelKey, rules: e.rules },
        },
        [
          e.type === "radio"
            ? _c(
                "el-radio-group",
                _vm._b(
                  {
                    model: {
                      value: e.value,
                      callback: function ($$v) {
                        _vm.$set(e, "value", $$v)
                      },
                      expression: "e.value",
                    },
                  },
                  "el-radio-group",
                  e.props,
                  false
                ),
                _vm._l(e.options, function (item) {
                  return _c("el-radio", { key: item, attrs: { label: item } }, [
                    _vm._v(_vm._s(item)),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          e.type === "checkbox"
            ? _c(
                "el-checkbox-group",
                {
                  model: {
                    value: e.value,
                    callback: function ($$v) {
                      _vm.$set(e, "value", $$v)
                    },
                    expression: "e.value",
                  },
                },
                _vm._l(e.options, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item, attrs: { label: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          e.type === "input"
            ? _c(
                "el-input",
                _vm._g(
                  _vm._b(
                    {
                      model: {
                        value: e.value,
                        callback: function ($$v) {
                          _vm.$set(e, "value", $$v)
                        },
                        expression: "e.value",
                      },
                    },
                    "el-input",
                    e.props,
                    false
                  ),
                  e.events
                )
              )
            : _vm._e(),
          _vm._v(" "),
          e.type === "select"
            ? _c(
                "el-select",
                _vm._b(
                  {
                    attrs: { clearable: "", multiple: e.multiple },
                    model: {
                      value: e.value,
                      callback: function ($$v) {
                        _vm.$set(e, "value", $$v)
                      },
                      expression: "e.value",
                    },
                  },
                  "el-select",
                  e.props,
                  false
                ),
                _vm._l(e.options, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          e.type === "tree"
            ? _c(
                "el-cascader",
                _vm._b(
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      options: e.options,
                      multiple: e.multiple,
                      filterable: "",
                    },
                    model: {
                      value: e.value,
                      callback: function ($$v) {
                        _vm.$set(e, "value", $$v)
                      },
                      expression: "e.value",
                    },
                  },
                  "el-cascader",
                  e.props,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          e.type === "date"
            ? _c(
                "el-date-picker",
                _vm._b(
                  {
                    attrs: { type: "date", "value-format": "yyyy-MM-dd" },
                    model: {
                      value: e.value,
                      callback: function ($$v) {
                        _vm.$set(e, "value", $$v)
                      },
                      expression: "e.value",
                    },
                  },
                  "el-date-picker",
                  e.props,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }