//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DynamicForm',
  props: {
    field: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    fields: {
      handler: function handler(n) {
        this.$emit('update:field', n);
      },
      deep: true
    },
    field: function field() {
      this.field.map(function (val) {
        val.label += ':';
        if (val.type == "tree") {
          val.value = ['尚未沟通', '新数据'];
        }
      });
    }
  }
};